import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import imagePlaceholder from './../assets/images/imagePlaceholder.svg'
import './../assets/styles/placeholder.scss'
import './../assets/styles/itemDetailComponent.scss'
import { Dropdown } from "react-bootstrap";
import { face_analysis_dummy, face_compare_dummy, face_search_dummy, label_detect_dummy, ppe_detect_dummy, text_detection_dummy } from './../utils'
//import { resultsImages } from './../utils'
import { resultsCheckboxes } from './../utils'
import { pages } from './../utils'
import { faceSearchItems } from './../utils'

import { Button } from './button'
import { ResultsData } from './resultsData'
import { ResultsImages } from './resultsImages'
import { ResultsCheckboxes } from './resultsCheckboxes'
import { loadingMessage,loadingMessageForFaceAnalysisOrPPE } from './../utils'
import AWS from 'aws-sdk'
import apiClient from "../api/apiClient";
import { css } from "@emotion/react";
import { primaryColor } from "../assets/colors";
import { ToastContainer, toast } from 'react-toastify';
import logService from "../log/logService";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Modal } from "react-bootstrap";
import label_detect_image from './../assets/images/dummy/label_detect_image.jpg'
import face_analysis_image from './../assets/images/dummy/face_analysis_image.jpg'
import ppe_detect_image from './../assets/images/dummy/ppe_detect_image.jpg'
import face_compare_one from './../assets/images/dummy/face_compare_one.jpg'
import face_compare_two from './../assets/images/dummy/face_compare_two.jpg'
import text_image from './../assets/images/dummy/text_image.jpg'
import elon_musk from './../assets/images/dummy/elon.jpg'
import elon_search from './../assets/images/dummy/elon_store.jpg'

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const ItemDetailComponent = (props) => {
    const [selectedMainPhoto, setSelectedMainPhoto] = useState(null);
    const [selectedFileTypeFile, setSelectedFileTypeFile] = useState(null);

    const [selectedPhotoOne, setSelectedPhotoOne] = useState(null);
    const [selectedFileTypeOneFile, setSelectedFileTypeOneFile] = useState(null);

    const [selectedPhotoTwo, setselectedPhotoTwo] = useState(null);
    const [selectedFileTypeTwoFile, setSelectedFileTypeTwoFile] = useState(null);

    const [heading, setHeading] = useState('');
    const [description, setDescription] = useState('');
    const [resultFlag, setResultFlag] = useState(false);
    const [faceFlag, setFaceFlag] = useState(false);
    
    //Label Detections
    const [labelDetecResult,setLabelDetecResult] = useState([loadingMessage]);

    //Face Analysis and PPE
    const [imageListOfResults, setImageListOfResults] = useState([]);
    const [selectedFaceResults,setSelectedFaceResults] = useState();
    const [selectedFace,setSelectedFace] = useState(loadingMessageForFaceAnalysisOrPPE);

    //Face Compare
    const [selectedSimilarity,setSelectedSimilarity] = useState([loadingMessage]);

    //Face Search
    const [allCollection,setAllCollection] = useState([])
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [faceMatchResult, setFaceMatchResult] = useState(null)
    const [faceMatchAPIResult, setFaceMatchAPIResult] = useState(null)

    //Text detection
    const [allDetectedText,setAllDetectedText] = useState({
        line : [], 
        word: []
    })

    const [loading, setLoading] = useState(false);

    const location = useLocation();

    const [result,setResult] = useState([])

    //Success Notification
    const notifySuccess = (message) => toast.success(`${message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    //Error Notification
    const notifyError = (message) => toast.error(`${message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    useEffect(() => {
        if (location.pathname === '/label_detect'){
            setResult(label_detect_dummy)
            setSelectedMainPhoto(label_detect_image)
        }else if (location.pathname === '/facial-analysis'){
            setImageListOfResults([])
            setSelectedFaceResults()
            setSelectedMainPhoto(face_analysis_image)
            fetchFaceAnalysisFromAWS(face_analysis_dummy)
            setSelectedFileTypeFile(face_analysis_image)
        }else if (location.pathname === '/ppe-detection'){
            setImageListOfResults([])
            setSelectedMainPhoto(ppe_detect_image)
            setSelectedFileTypeFile(ppe_detect_image)
        }else if (location.pathname === '/faces-comparison'){
            setImageListOfResults([])
            setSelectedPhotoOne(face_compare_one)
            setSelectedFileTypeOneFile(face_compare_one)
            setselectedPhotoTwo(face_compare_two)
            setSelectedFileTypeTwoFile(face_compare_two)
        }else if (location.pathname === '/text-detection'){
            setSelectedMainPhoto(text_image)
            setSelectedFileTypeFile(text_image)
        }

        if (location.pathname === '/face-search-verification/search-face-by-image') {
            setFaceFlag(true)
            let item = faceSearchItems.filter((item) => (item.key === 'search-face-by-image'))
            setHeading(item[0].heading)
            setSelectedMainPhoto(elon_musk)
            const getAllCollection = async () => {
                const allCollection = await retrieveAllCollection()
                if (allCollection){
                    setAllCollection(allCollection.collections)
                }else{
                    setAllCollection([])
                }
            }
            getAllCollection();
        } else {
            setFaceFlag(false)
            let item = pages.filter((item) => (item.key === props.itemkey))
            setHeading(item[0].heading)
            setDescription(item[0].description)
        }
    }, [])

    //GO button actions
    const buttonClick = () => {
        if (location.pathname === '/facial-analysis'){
            setImageListOfResults([])
            setSelectedFaceResults()
            fetchFaceAnalysisFromAWS(face_analysis_dummy)
            setResultFlag(true)
        }else if (location.pathname === '/label_detect'){
            fetchLabelFromAWS(selectedFileTypeFile)
        }else if (location.pathname === '/face-search-verification/search-face-by-image'){
            setFaceMatchResult(null)
            setFaceMatchAPIResult(null)
            startSearchInCollection(face_search_dummy)
        }else if (location.pathname === '/ppe-detection'){
            startPPEDetection(ppe_detect_dummy)
        }else if (location.pathname === '/faces-comparison'){
            fetchCompareFaceFromAWS(face_compare_dummy)
        }else if (location.pathname === '/text-detection'){
            startAWSTextDetection(text_detection_dummy)
        }
    }

    const changeHandler = async (event) => {
        notifyError("Upload disabled in demo")
        if (event.target.files[0]) {
            setSelectedFileTypeFile(event.target.files[0])
            setSelectedMainPhoto(URL.createObjectURL(event.target.files[0]))
        }
    }
    
    const changeHandlerFaceOne = (event) => {
        if (event.target.files[0]) {
            setSelectedPhotoOne(URL.createObjectURL(event.target.files[0]));
            setSelectedFileTypeOneFile(event.target.files[0]);
        }
    }

    const changeHandlerFaceTwo = (event) => {
        if (event.target.files[0]) {
            setselectedPhotoTwo(URL.createObjectURL(event.target.files[0]));
            setSelectedFileTypeTwoFile(event.target.files[0]);
        }
    }

    //Set Selected Face Results on select for Face Analysis
    const getSelectedFaceForFaceAnalysis = (index) => {
        setSelectedFace(selectedFaceResults[index])
    }

    //Set Selected Face Results on select for Face Compare
    const getSelectedFaceForFaceCompare = (index) => {
        setSelectedSimilarity(selectedFaceResults[index])
    }

    const getConfidenceWithMark = (confidence) => {
        return `${confidence.toFixed(2)}%`
    }

    //AWS Label Detection
    const fetchLabelFromAWS = (file) => {
        setLabelDetecResult(result);
        setResultFlag(true)
    }

    //AWS Face Analysis
    const fetchFaceAnalysisFromAWS = (results) => {
        var allDetectedFaceResult = [];
        var resultValueForAllFaces = [];
        results.forEach(data => {
            var allDetectedFaceGeneralList = [];
            var allDetectedFaceFeatureList = [];
            var allDetectedFaceEmotionList = [];
            //Face Image
            const boundingBox = data.BoundingBox
            allDetectedFaceResult.push({
                image : face_analysis_image, boundingBox: boundingBox
            });
            //General
                //Face confidence
            allDetectedFaceGeneralList.push({
                name : "looks like a face", value: getConfidenceWithMark(data.Confidence)
            });
                //Gender
            allDetectedFaceGeneralList.push({
                name : `appears to be ${data.Gender.Value}`, value: getConfidenceWithMark(data.Gender.Confidence)
            });
                //Get Age
            let low  = data.AgeRange.Low
            let high = data.AgeRange.High
            //console.log(`The detected face is between: ${low} and ${high} years old`)
            allDetectedFaceGeneralList.push({
                name : `age range`, value: `${low} - ${high} years old`
            });
                //Smile
            allDetectedFaceGeneralList.push({
                name : `smiling ${data.Smile.Value}`, value: getConfidenceWithMark(data.Smile.Confidence)
            });

            //Feature
                //mouth is open
            allDetectedFaceFeatureList.push({
                name : `mouth is open ${data.MouthOpen.Value}`, value: getConfidenceWithMark(data.MouthOpen.Confidence)
            });
                //does not have a mustache
            allDetectedFaceFeatureList.push({
                name : `does not have a mustache ${data.Mustache.Value}`, value: getConfidenceWithMark(data.Mustache.Confidence)
            });
                //does not have a beard
            allDetectedFaceFeatureList.push({
                name : `does not have a beard ${data.Beard.Value}`, value: getConfidenceWithMark(data.Beard.Confidence)
            });
                //eyes are open
            allDetectedFaceFeatureList.push({
                name : `eyes are open ${data.EyesOpen.Value}`, value: getConfidenceWithMark(data.EyesOpen.Confidence)
            });
                //eyes are open
            allDetectedFaceFeatureList.push({
                name : `wearing glasses ${data.Eyeglasses.Value}`, value: getConfidenceWithMark(data.Eyeglasses.Confidence)
            });
                //wearing sunglasses
            allDetectedFaceFeatureList.push({
                name : `wearing sunglasses ${data.Sunglasses.Value}`, value: getConfidenceWithMark(data.Sunglasses.Confidence)
            });

            //Emotions
            data.Emotions.forEach(Emotion => {
                allDetectedFaceEmotionList.push({
                    name : `${Emotion.Type}`, value: getConfidenceWithMark(Emotion.Confidence)
                });
            })
            resultValueForAllFaces.push({
                first : allDetectedFaceGeneralList, 
                second: allDetectedFaceFeatureList, 
                third : allDetectedFaceEmotionList
            });
        }) // for response.faceDetails
        setImageListOfResults(allDetectedFaceResult);
        setSelectedFaceResults(resultValueForAllFaces);
        if (resultValueForAllFaces.length > 0){
            setSelectedFace(resultValueForAllFaces[0]);
        }else{
            notifyError(`No face found`)
        }
    }

    //AWS Compare Face
    const fetchCompareFaceFromAWS = async (compareFace) => {
        //console.log("Start AWS Face Compare")
        var allDetectedFaceResult = [];
        var resultValueForAllFaces = [];

        compareFace.FaceMatches.forEach(data => {
            let boundingBox = data.Face.BoundingBox
            let imageURL = selectedPhotoTwo
            allDetectedFaceResult.push({
                image : imageURL, boundingBox: boundingBox
            });
            var resultValueForOneFace = [];
            resultValueForOneFace.push({
                name : "Similarity", value: getConfidenceWithMark(data.Similarity)
            });
            resultValueForOneFace.push({
                name : "Confidence", value: getConfidenceWithMark(data.Face.Confidence)
            });
            resultValueForAllFaces.push(resultValueForOneFace);
        }) // for compareFace_dummy
        setImageListOfResults(allDetectedFaceResult);
        setSelectedFaceResults(resultValueForAllFaces);
        if (resultValueForAllFaces.length > 0){
            notifySuccess(`Face compared success.`)
            setResultFlag(true)
            setSelectedSimilarity(resultValueForAllFaces[0]);
        }else{
            setResultFlag(false)
            notifyError(`No match face found`)
        }
    }

    //AWS search face in collection
    const startSearchInCollection = async (searchImage) => {
        if (selectedCollection === null){
            notifyError(`Select a collection to continue`)
            return
        }
        if (searchImage === null || searchImage === undefined){
            notifyError(`Upload a photo to continue`)
            return
        }
        //console.log("AWS search face in collection")
        if (searchImage.FaceMatches.length > 0){
            let faceId = searchImage.FaceMatches[0].Face.FaceId
            setFaceMatchResult(searchImage.FaceMatches[0])
            notifySuccess(`Face found in collection name ${selectedCollection.collectionName}`)
            retrieveFacesById(faceId)
        }else{
            notifyError(`Error! No face found.`)
        }
    }

    //AWS PPE detection
    const startPPEDetection = async (response_ppe) => {
        if (response_ppe.Persons.length > 0){
            notifySuccess(`Successfully detected`)
            var resultValueForAllPersons = [];
            var allDetectedFaceResult = [];

            response_ppe.Persons.forEach(person => {
                let boundingBox = person.BoundingBox
                let imageURL = selectedMainPhoto
                allDetectedFaceResult.push({
                    image : imageURL, boundingBox: boundingBox
                });
                var allFirstResultSet = [];
                var allSecondResultSet = [];
                var allThirdResultSet = [];

                //ProtectiveEquipmentBodyPart
                if (person.BodyParts.length > 0){
                    //Person detect
                    allFirstResultSet.push({
                        name : `Person detected`, value: getConfidenceWithMark(person.Confidence)
                    });
                    person.BodyParts.forEach(bodypart =>{
                        //Face
                        if (bodypart.Name === 'FACE'){
                            allFirstResultSet.push({
                                name : `Face detected`, value: getConfidenceWithMark(bodypart.Confidence)
                            });
                            bodypart.EquipmentDetections.forEach(equipment => {
                                if (equipment.Type === "FACE_COVER"){
                                    allFirstResultSet.push({
                                        name : `Face cover detected`, value: getConfidenceWithMark(equipment.Confidence)
                                    });
                                    allFirstResultSet.push({
                                        name : `Face cover on nose : ${equipment.CoversBodyPart.Value}`, value: getConfidenceWithMark(equipment.CoversBodyPart.Confidence)
                                    });
                                }
                            });
                        }
                        //LEFT_HAND
                        if (bodypart.Name === 'LEFT_HAND'){
                            allSecondResultSet.push({
                                name : `Left hand detected`, value: getConfidenceWithMark(bodypart.Confidence)
                            });
                            bodypart.EquipmentDetections.forEach(equipment => {
                                if (equipment.Type === "HAND_COVER"){
                                    allSecondResultSet.push({
                                        name : `Left hand cover detected`, value: getConfidenceWithMark(equipment.Confidence)
                                    });
                                    allSecondResultSet.push({
                                        name : `Left hand cover on left hand :${equipment.CoversBodyPart.Value}`, value: getConfidenceWithMark(equipment.CoversBodyPart.Confidence)
                                    });
                                }
                            });
                        }
                        //LEFT_HAND
                        if (bodypart.Name === 'RIGHT_HAND'){
                            allSecondResultSet.push({
                                name : `Right hand detected`, value: getConfidenceWithMark(bodypart.Confidence)
                            });
                            bodypart.EquipmentDetections.forEach(equipment => {
                                if (equipment.Type === "HAND_COVER"){
                                    allSecondResultSet.push({
                                        name : `Right hand cover detected`, value: getConfidenceWithMark(equipment.Confidence)
                                    });
                                    allSecondResultSet.push({
                                        name : `Right hand cover on left hand :${equipment.CoversBodyPart.Value}`, value: getConfidenceWithMark(equipment.CoversBodyPart.Confidence)
                                    });
                                }
                            });
                        }
                        //HEAD
                        if (bodypart.Name === 'HEAD'){
                            allThirdResultSet.push({
                                name : `Head detected`, value: getConfidenceWithMark(bodypart.Confidence)
                            });
                            bodypart.EquipmentDetections.forEach(equipment => {
                                if (equipment.Type === "HEAD_COVER"){
                                    allThirdResultSet.push({
                                        name : `Head cover detected`, value: getConfidenceWithMark(equipment.Confidence)
                                    });
                                    allThirdResultSet.push({
                                        name : `Head cover on head :${equipment.CoversBodyPart.Value}`, value: getConfidenceWithMark(equipment.CoversBodyPart.Confidence)
                                    });
                                }
                            });
                        }
                    });
                    resultValueForAllPersons.push({
                        first : allFirstResultSet, 
                        second: allSecondResultSet, 
                        third : allThirdResultSet
                    });
                }else{
                    resultValueForAllPersons.push({
                        first : [], 
                        second: [], 
                        third : []
                    });
                }
            })
            setImageListOfResults(allDetectedFaceResult);
            setSelectedFaceResults(resultValueForAllPersons);
            if (resultValueForAllPersons.length > 0){
                setResultFlag(true)
                setSelectedFace(resultValueForAllPersons[0]);
            }else{
                setResultFlag(false)
                notifyError(`No person found`)
            }
        }else{
            notifyError(`No person found.`)
        }
    }

    //AWS text detections
    const startAWSTextDetection = async (response) => {
        //console.log("start AWS text detections")
        console.log(response)
        const allLineText = []
        const allWordText = []
        allLineText.push({
            name : `...LINE...`, value: ''
        });
        allWordText.push({
            name : `...WORD...`, value: ''
        });
        if (response.TextDetections.length > 0){
            response.TextDetections.forEach(textDetect =>{
                if (textDetect.Type === "LINE"){
                    allLineText.push({
                        name : textDetect.DetectedText, value: getConfidenceWithMark(textDetect.Confidence)
                    });
                }
                if (textDetect.Type === "WORD"){
                    allWordText.push({
                        name : textDetect.DetectedText, value: getConfidenceWithMark(textDetect.Confidence)
                    });
                }
            })
            const newAllDetectedText = {
                line : allLineText, 
                word: allWordText
            };
            setAllDetectedText(newAllDetectedText)
            setResultFlag(true)
            notifySuccess(`Text successfully detected in the image`)
        }else{
            notifyError(`Error! No text found.`)
        }
    }

    //DB retrieve faces by id
    const retrieveFacesById = async (facelId) =>{
        try{
            setLoading(true)
            const requestHeadersWithJWT = {
                'Content-Type': 'application/json',
                'Authorization' : localStorage.getItem("jwt-token")
              };
            const response = await apiClient.get(`/getbyfaceid/${facelId}`,{
                headers: requestHeadersWithJWT
            })
            setLoading(false)
            if (response.status === 200){
                if (response.data.length > 0){
                    setFaceMatchAPIResult(response.data[0])
                    setResultFlag(true)
                    notifySuccess(`Face found with below details.`)
                }else{
                    notifyError(`Error! No face found in local data`)
                }
            }else{
                notifyError(`Error! No face found in local data`)
            }
        }catch(error) {
            setLoading(false)
            logService.log(error)
            notifyError(`${error}`)
            if (error.response) {
                // Request made and server responded
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
            return error.response
        }
    };

    //DB Retrieve Collections
    const retrieveAllCollection = async () =>{
        try{
            setLoading(true)
            const requestHeadersWithJWT = {
                'Content-Type': 'application/json',
                'Authorization' : localStorage.getItem("jwt-token")
            };
            const response = await apiClient.get(`/collections`,{
                headers: requestHeadersWithJWT
            })
            setLoading(false)
            if (response.status === 200){
                //Update list after add
                return response.data
            }else{
                notifyError(`Error ${response}`)
                return []
            } 
        }catch(error) {
            setLoading(false)
            logService.log(error)
            notifyError(`${error}`)
            if (error.response) {
                // Request made and server responded
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
            return []
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="container">
                <div className="row">
                    <div className={(props.itemkey === 'faces-comparison') ? "col-sm-12 col-md-6 col-lg-6" : "col-sm-12 col-md-4 col-lg-4"}>
                        {
                            (props.itemkey === 'faces-comparison') && (
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="detail-image-placeholder-container-face">
                                            {
                                                (selectedPhotoOne) ? (
                                                    <div width='210' height='230'>
                                                        <img src={selectedPhotoOne} alt="placeholder face compare" width='100%'/>
                                                    </div>
                                                ) : (
                                                    <img src={imagePlaceholder} alt="placeholder"/>
                                                )
                                            }
                                            </div>
                                            <label className="custom-file-upload-face">
                                                <input accept="image/png, image/jpeg" type="file" onChange={changeHandlerFaceOne} disabled/>
                                                Upload File (Disabled in demo)
                                            </label>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="detail-image-placeholder-container-face">
                                            {
                                                (selectedPhotoTwo) ? (
                                                    <div width='210' height='230'>
                                                        <img src={selectedPhotoTwo} alt="placeholder" width='100%'/>
                                                    </div>
                                                ) : (
                                                    <img src={imagePlaceholder} alt="placeholder"/>
                                                )
                                            }
                                            </div>
                                            <label className="custom-file-upload-face">
                                                <input accept="image/png, image/jpeg" type="file" onChange={changeHandlerFaceTwo} disabled/>
                                                Upload File (Disabled in demo)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            (props.itemkey === 'ppe-detection') && (
                                <>
                                    <div className="detail-image-placeholder-container">
                                        {
                                            (selectedMainPhoto) ? (
                                                <div width='210' height='230'>
                                                    <img src={selectedMainPhoto} alt="placeholder" width='100%'/>
                                                </div>
                                            ) : (
                                                <img src={imagePlaceholder} alt="placeholder"/>
                                            )
                                        }
                                    </div>
                                    <label className="custom-file-upload-face">
                                        <input accept="image/png, image/jpeg" type="file" onChange={changeHandler} disabled/>
                                        Upload File (Disabled)
                                    </label>
                                </>
                            )
                        }
                        {
                            ((props.itemkey !== 'faces-comparison') && (props.itemkey !== 'ppe-detection')) && (
                                <div className="detail-image-placeholder-container">
                                    {
                                        (selectedMainPhoto) ? (
                                            <div width='300' height='311'>
                                                <img src={selectedMainPhoto} alt="placeholder" width='100%'/>
                                            </div>
                                        ) : (
                                            <img src={imagePlaceholder} alt="placeholder"/>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className={(resultFlag) ? "col-sm-12 col-md-5 col-lg-5" : "col-sm-12 col-md-6 col-lg-6"}>
                        <h4 className="item-heading">{heading}</h4>
                        <p className="item-description">{description}</p>
                        <p className="warning-msg">Upload Photo Disabled in Demo. <br/>Image must be .jpeg or .png format and no larger than 5MB. Your image isn't stored.</p>
                        {
                            (faceFlag) && (
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                    {
                                        (selectedCollection) ? (
                                            selectedCollection.collectionName
                                        ) : (
                                            'Select Collection'
                                        )
                                    }
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            allCollection.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={(e) => setSelectedCollection(item)}>{item.collectionName}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        }
                        <div className={(props.itemkey === 'ppe-detection') ? "file-upload-div-ppe" : "file-upload-div"}>
                            {
                                ((props.itemkey !== 'faces-comparison') && (props.itemkey !== 'ppe-detection')) && (
                                    <label className="custom-file-upload">
                                        <input accept="image/png, image/jpeg" type="file" onChange={changeHandler} disabled/>
                                        Upload File (Disabled in demo)
                                    </label>
                                )
                            }
                            {
                                (props.itemkey === 'ppe-detection') && (
                                    <ResultsCheckboxes checkboxes={resultsCheckboxes}/>
                                )
                            }
                            <div className="navigate-button">
                                <Button text="GO" onClick={buttonClick}/>
                            </div>
                        </div>
                    </div>
                    {
                        ((props.itemkey === 'label_detect') && (resultFlag)) && (
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <h5 className="results-heading">Results</h5>
                                <ResultsData result={labelDetecResult} />
                            </div>
                        )
                    }
                    {
                        (((props.itemkey === 'facial-analysis') || (props.itemkey === 'ppe-detection')) && (resultFlag)) && (
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <h5 className="results-heading">Results</h5>
                                <ResultsImages images={imageListOfResults} returnImage={getSelectedFaceForFaceAnalysis}/>
                            </div>
                        )
                    }
                </div>
                {
                    ((props.itemkey === 'facial-analysis') && (resultFlag)) && (
                        <div className="face-results-div">
                            <h5 className="results-heading">Selected Face Results</h5>
                            <div className="row">
                                <div className="col-sm-12 col-md-5 col-lg-5">
                                    <ResultsData result={selectedFace?.first} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <ResultsData result={selectedFace?.second} />
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                    <ResultsData result={selectedFace?.third} />
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    ((props.itemkey === 'faces-comparison') && (resultFlag)) && (
                        <div className="face-results-div">
                            <h5 className="results-heading">Selected Face Results</h5>
                            <h5 className="results-heading results-heading-secondary">Face Matches</h5>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ResultsImages images={imageListOfResults} returnImage={getSelectedFaceForFaceCompare}/>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ResultsData result={selectedSimilarity} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    ((props.itemkey === 'ppe-detection') && (resultFlag)) && (
                        <div className="face-results-div">
                            <h5 className="results-heading">Selected Face Results</h5>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <ResultsData result={selectedFace.first} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <ResultsData result={selectedFace.second} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <ResultsData result={selectedFace.third} />
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    (faceFlag) && (resultFlag) && (
                        <div className="face-results-div">
                            <h5 className="results-heading">Result</h5>
                            <div className="row">
                                <div className="col-12">
                                <div className="result-face-data-div">
                                    <div className="face-detail-item">
                                        {   
                                            (selectedCollection) ? (
                                                <h4>Face found in Collection {selectedCollection.collectionName}</h4>
                                            ) : (
                                                <h4>Face found in Collection</h4>
                                            ) 
                                        }
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="detail-image-placeholder-container">
                                                    <img src={elon_search} width='100%' alt="placeholder" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <p className="desc-text">Similarity : <label style={{color: '#f5ac47'}}>{faceMatchResult.Similarity}</label></p>
                                                <p className="desc-text">Full Name : {faceMatchAPIResult.fullName}</p>
                                                <p className="desc-text">Email Address: {faceMatchAPIResult.emailAddress}</p>
                                                <p className="desc-text">Face Id : {faceMatchAPIResult.faceId}</p>
                                                <p className="desc-text">External Id : {faceMatchAPIResult.externalId}</p>
                                                <p className="desc-text">Created at: {faceMatchAPIResult.createdAt}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    ((props.itemkey === 'text-detection') && (resultFlag)) && (
                        <div className="face-results-div">
                            <h5 className="results-heading">Text Results</h5>
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>Detect text type by Line</p>
                                    <ResultsData result={allDetectedText?.line} />
                                </div>
                                <div className="col-sm-6">
                                    <p>Detect text type by Word</p>
                                    <ResultsData result={allDetectedText?.word} />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <Modal show={loading} centered>
                <div className="d-flex flex-column align-items-center">
                <ScaleLoader color={primaryColor} loading={loading} css={override} size={150} />
                <div>
                <p>Loading...</p>
                </div>
                </div>
            </Modal>
        </>
    )
}