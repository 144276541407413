import placeholderImage from "./assets/images/imagePlaceholder.svg";

export const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

export const loadingMessageForFaceAnalysisOrPPE = {
  first:[
    {
    name: "General Loading...",
    value: " "
    }
  ],
  second:[
    {
    name: "Feature Loading...",
    value: " "
    }
  ],
  third: [
    {
    name: "Emotions Loading...",
    value: " "
    }
  ]
};

export const loadingMessage = {
  name: "Loading...",
  value: " ",
};

export const pages = [
  {
    key: "label_detect",
    imageName: "label_detect.png",
    heading: "Object and scene detection",
    description:
      "With Object and scene detection, you can identify thousands of objects (such as bike, telephone, building), and scenes (such as parking lot, beach, city).",
  },
  {
    key: "facial-analysis",
    imageName: "Face-Analysis.png",
    heading: "Facial analysis",
    description:
      "With Facial Analysis, you can easily detect when faces appear in images and videos and get attributes such as gender, age range, eyes open, glasses, facial hair for each.",
  },
  {
    key: "faces-search-verification",
    imageName: "Face-search.png",
    heading: "Face search and verification",
    description:
      "Face search verification provides fast and accurate face search, allowing you to identify a person in a photo using your private repository of face images.",
  },
  {
    key: "ppe-detection",
    imageName: "worker-with-bb.png",
    heading: "Personal Protective Equipment (PPE) detection",
    description:
      "Automatically detect Personal Protective Equipment (PPE) such as face covers, head covers, and hand covers on persons in images.",
  },
  {
    key: "faces-comparison",
    imageName: "face_compare.png",
    heading: "Face comparison",
    description:
      "Compare faces to see how closely they match based on a similarity percentage.",
  },
  {
    key: "text-detection",
    imageName: "rekognition-text-detection.png",
    heading: "Text detection",
    description:
      "Rekognition can detect text in images. It can then convert the detected text into machine-readable text.",
  }
];

export const resultsData = [
  {
    key: "label_detect",
    values: [
      {
          name: "Pedestrian",
          value: "99.96%"
      },
      {
          name: "Person",
          value: "99.96%"
      },
      {
          name: "Human",
          value: "99.96%"
      },
      {
        name: "Downtown",
          value: "98.44%"
      },
      {
        name: "Urban",
          value: "98.44%"
      },
      {
        name: "City",
          value: "98.44%"
      },
      {
        name: "Building",
          value: "98.44%"
      }
  ],
  },
  {
    key: "facial-analysis",
    values: [
      {
        first: [
          {
            name: "looks like a face",
            value: "98.9 %",
          },
          {
            name: "appears to be female",
            value: "98.9 %",
          },
          {
            name: "age range",
            value: "5 - 15 years old",
          },
          {
            name: "smiling",
            value: "99.9 %",
          },
          {
            name: "appears to be happy",
            value: "98.9 %",
          },
        ],
      },
      {
        second: [
          {
            name: "mouth is open",
            value: "99.9 %",
          },
          {
            name: "does not have a mustache",
            value: "98.9 %",
          },
          {
            name: "does not have a beard",
            value: "98.9 %",
          },
          {
            name: "eyes are open",
            value: "97.9 %",
          },
          {
            name: "wearing glasses",
            value: "99.9 %",
          },
          {
            name: "wearing sunglasses",
            value: "99.9 %",
          },
        ],
      },
      {
        third: [
          {
            name: "HAPPY",
            value: "92.9 %",
          },
          {
            name: "SURPRISED",
            value: "70.9 %",
          },
          {
            name: "CONFUSED",
            value: "70.9 %",
          },
          {
            name: "ANGRY",
            value: "40.9 %",
          },
          {
            name: "CALM",
            value: "30.9 %",
          },
          {
            name: "FEAR",
            value: "30.9 %",
          },
        ],
      },
    ],
  },
  {
    key: "faces-comparison",
    values: [
      {
        name: "Similarity",
        value: "98.9 %",
      },
      {
        name: "Confidence",
        value: "98.9 %",
      },
    ],
  },
  {
    key: "ppe-detection",
    values: [
      {
        first: [
          {
            name: "Person detected",
            value: "98.9 %",
          },
          {
            name: "Face detected",
            value: "98.9 %",
          },
          {
            name: "Face cover detected",
            value: "99.6 %",
          },
          {
            name: "Face cover on nose",
            value: "99.9 %",
          },
        ],
      },
      {
        second: [
          {
            name: "Left hand detected",
            value: "99.9 %",
          },
          {
            name: "Hand cover on left hand : true",
            value: "98.9 %",
          },
          {
            name: "Right hand detected",
            value: "98.9 %",
          },
          {
            name: "Hand cover on right hand",
            value: "97.9 %",
          },
        ],
      },
      {
        third: [
          {
            name: "Head detected",
            value: "92.9 %",
          },
          {
            name: "Head cover detected",
            value: "70.9 %",
          },
          {
            name: "Head cover on head",
            value: "70.9 %",
          },
        ],
      },
    ],
  },
];

export const resultsImages = [
  placeholderImage,
  placeholderImage,
  placeholderImage,
  placeholderImage,
  placeholderImage,
];

export const resultsCheckboxes = ["FACE COVER", "HAND COVER", "HEAD COVER"];

export const faceSearchItems = [
  {
    key: "manage-face-collection",
    image: "faceAddWithColletion.png",
    heading: "Manage Faces and Collections",
  },
  {
    key: "search-face-by-image",
    image: "Face-search.png",
    heading: "Search Face From List By Image",
  },
];

export const faceCollectionData = [
    {
        name: 'USA Office',
        id: 'XXDSD222',
        createdAt: '09:54 AM 02/06/2021'
    },
    {
        name: 'Torronto Office',
        id: 'CCDSD222',
        createdAt: '09:54 AM 02/06/2021'
    },
    {
        name: 'Head Office',
        id: 'CCDSD222',
        createdAt: '09:54 AM 02/06/2021'
    },
    {
        name: 'Marine Square',
        id: 'CCDSD222',
        createdAt: '09:54 AM 02/06/2021'
    }
];

export const faceCollectionIDData = [
  {
      name: 'ABC',
      id: '0b683aed-a0f1-48b2-9b5e-139e9cc2a757',
      createdAt: '09:54 AM 02/06/2021'
  },
  {
      name: 'DEF',
      id: '0b683aed-a0f1-48b2-9b5e-139e9cc2a757',
      createdAt: '09:54 AM 02/06/2021'
  },
  {
      name: 'GHQ',
      id: '0b683aed-a0f1-48b2-9b5e-139e9cc2a757',
      createdAt: '09:54 AM 02/06/2021'
  },
  {
      name: 'XYQ',
      id: '0b683aed-a0f1-48b2-9b5e-139e9cc2a757',
      createdAt: '09:54 AM 02/06/2021'
  },
  {
      name: 'IUY',
      id: '0b683aed-a0f1-48b2-9b5e-139e9cc2a757',
      createdAt: '09:54 AM 02/06/2021'
  },

];

export const face_search_dummy = {
  "SearchedFaceBoundingBox": {
      "Width": 0.14912733435630798,
      "Height": 0.4340377449989319,
      "Left": 0.25197190046310425,
      "Top": 0.17426283657550812
  },
  "SearchedFaceConfidence": 99.99836730957031,
  "FaceMatches": [
      {
          "Similarity": 99.99388122558594,
          "Face": {
              "FaceId": "d75ea8d1-52c9-4c5f-b44f-7a1ec3b8da2c",
              "BoundingBox": {
                  "Width": 0.34224799275398254,
                  "Height": 0.7654359936714172,
                  "Left": 0.14862200617790222,
                  "Top": 0.1396930068731308
              },
              "ImageId": "c0e2bdbd-76b9-3226-aaac-7b49407eabc8",
              "ExternalImageId": "xFace1628432360",
              "Confidence": 99.99330139160156
          }
      }
  ],
  "FaceModelVersion": "5.0"
};

export const text_detection_dummy = {
  "TextDetections": [
      {
          "DetectedText": "WARNING",
          "Type": "LINE",
          "Id": 0,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.4015393555164337,
                  "Height": 0.24559764564037323,
                  "Left": 0.37715595960617065,
                  "Top": 0.13013936579227448
              },
              "Polygon": [
                  {
                      "X": 0.37715595960617065,
                      "Y": 0.30429184436798096
                  },
                  {
                      "X": 0.7492719292640686,
                      "Y": 0.13013936579227448
                  },
                  {
                      "X": 0.778695285320282,
                      "Y": 0.20158450305461884
                  },
                  {
                      "X": 0.40657931566238403,
                      "Y": 0.3757370114326477
                  }
              ]
          }
      },
      {
          "DetectedText": "TRESPASSING",
          "Type": "LINE",
          "Id": 1,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.3528481721878052,
                  "Height": 0.19147084653377533,
                  "Left": 0.4268152415752411,
                  "Top": 0.23106436431407928
              },
              "Polygon": [
                  {
                      "X": 0.4268152415752411,
                      "Y": 0.37064236402511597
                  },
                  {
                      "X": 0.7605661749839783,
                      "Y": 0.23106436431407928
                  },
                  {
                      "X": 0.7796633839607239,
                      "Y": 0.2829572260379791
                  },
                  {
                      "X": 0.4459124803543091,
                      "Y": 0.4225352108478546
                  }
              ]
          }
      },
      {
          "DetectedText": "NO",
          "Type": "LINE",
          "Id": 2,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.07838218659162521,
                  "Height": 0.06740417331457138,
                  "Left": 0.37003764510154724,
                  "Top": 0.3762578070163727
              },
              "Polygon": [
                  {
                      "X": 0.37003764510154724,
                      "Y": 0.40170055627822876
                  },
                  {
                      "X": 0.43365150690078735,
                      "Y": 0.3762578070163727
                  },
                  {
                      "X": 0.44841983914375305,
                      "Y": 0.4182192385196686
                  },
                  {
                      "X": 0.38480594754219055,
                      "Y": 0.44366195797920227
                  }
              ]
          }
      },
      {
          "DetectedText": "Restricted Area",
          "Type": "LINE",
          "Id": 3,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.37802866101264954,
                  "Height": 0.1811574399471283,
                  "Left": 0.3855441212654114,
                  "Top": 0.3620469868183136
              },
              "Polygon": [
                  {
                      "X": 0.3855441212654114,
                      "Y": 0.4864256978034973
                  },
                  {
                      "X": 0.746349036693573,
                      "Y": 0.3620469868183136
                  },
                  {
                      "X": 0.7635727524757385,
                      "Y": 0.4188257157802582
                  },
                  {
                      "X": 0.4027678668498993,
                      "Y": 0.5432044267654419
                  }
              ]
          }
      },
      {
          "DetectedText": "Keep Out",
          "Type": "LINE",
          "Id": 4,
          "Confidence": 99.64099884033203,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.24156036972999573,
                  "Height": 0.1428108662366867,
                  "Left": 0.4463132619857788,
                  "Top": 0.4544573426246643
              },
              "Polygon": [
                  {
                      "X": 0.4463132619857788,
                      "Y": 0.5288187265396118
                  },
                  {
                      "X": 0.667635977268219,
                      "Y": 0.4544573426246643
                  },
                  {
                      "X": 0.6878736019134521,
                      "Y": 0.5229068398475647
                  },
                  {
                      "X": 0.46655091643333435,
                      "Y": 0.5972682237625122
                  }
              ]
          }
      },
      {
          "DetectedText": "Authorized",
          "Type": "LINE",
          "Id": 5,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.2809741497039795,
                  "Height": 0.12783019244670868,
                  "Left": 0.4275312125682831,
                  "Top": 0.5817215442657471
              },
              "Polygon": [
                  {
                      "X": 0.4275312125682831,
                      "Y": 0.6460267901420593
                  },
                  {
                      "X": 0.6950692534446716,
                      "Y": 0.5817215442657471
                  },
                  {
                      "X": 0.708505392074585,
                      "Y": 0.6452465057373047
                  },
                  {
                      "X": 0.4409673511981964,
                      "Y": 0.7095517516136169
                  }
              ]
          }
      },
      {
          "DetectedText": "Personnel Only",
          "Type": "LINE",
          "Id": 6,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.385682076215744,
                  "Height": 0.1510029435157776,
                  "Left": 0.39213359355926514,
                  "Top": 0.6464658975601196
              },
              "Polygon": [
                  {
                      "X": 0.39213359355926514,
                      "Y": 0.7160861492156982
                  },
                  {
                      "X": 0.7644233703613281,
                      "Y": 0.6464658975601196
                  },
                  {
                      "X": 0.7778156995773315,
                      "Y": 0.7278485894203186
                  },
                  {
                      "X": 0.40552589297294617,
                      "Y": 0.7974688410758972
                  }
              ]
          }
      },
      {
          "DetectedText": "U.S. Customs and",
          "Type": "LINE",
          "Id": 7,
          "Confidence": 99.91336059570312,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.18834106624126434,
                  "Height": 0.05567583441734314,
                  "Left": 0.45181429386138916,
                  "Top": 0.8335350155830383
              },
              "Polygon": [
                  {
                      "X": 0.45181429386138916,
                      "Y": 0.8565937876701355
                  },
                  {
                      "X": 0.6365731954574585,
                      "Y": 0.8335350155830383
                  },
                  {
                      "X": 0.6401553750038147,
                      "Y": 0.8661521077156067
                  },
                  {
                      "X": 0.455396443605423,
                      "Y": 0.8892108798027039
                  }
              ]
          }
      },
      {
          "DetectedText": "Border Protection",
          "Type": "LINE",
          "Id": 8,
          "Confidence": 99.4883804321289,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.19658057391643524,
                  "Height": 0.04907841607928276,
                  "Left": 0.4533342123031616,
                  "Top": 0.8714060187339783
              },
              "Polygon": [
                  {
                      "X": 0.4533342123031616,
                      "Y": 0.8909258246421814
                  },
                  {
                      "X": 0.6472971439361572,
                      "Y": 0.8714060187339783
                  },
                  {
                      "X": 0.6499148011207581,
                      "Y": 0.900964617729187
                  },
                  {
                      "X": 0.45595183968544006,
                      "Y": 0.9204844236373901
                  }
              ]
          }
      },
      {
          "DetectedText": "WARNING",
          "Type": "WORD",
          "Id": 9,
          "ParentId": 0,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.4096924364566803,
                  "Height": 0.07725164294242859,
                  "Left": 0.37819984555244446,
                  "Top": 0.30457746982574463
              },
              "Polygon": [
                  {
                      "X": 0.37819984555244446,
                      "Y": 0.30457746982574463
                  },
                  {
                      "X": 0.7489677667617798,
                      "Y": 0.13028168678283691
                  },
                  {
                      "X": 0.778695285320282,
                      "Y": 0.20158450305461884
                  },
                  {
                      "X": 0.40627580881118774,
                      "Y": 0.375
                  }
              ]
          }
      },
      {
          "DetectedText": "TRESPASSING",
          "Type": "WORD",
          "Id": 10,
          "ParentId": 1,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.3614404499530792,
                  "Height": 0.05447446554899216,
                  "Left": 0.4269199073314667,
                  "Top": 0.37059858441352844
              },
              "Polygon": [
                  {
                      "X": 0.4269199073314667,
                      "Y": 0.37059858441352844
                  },
                  {
                      "X": 0.7605285048484802,
                      "Y": 0.2315140813589096
                  },
                  {
                      "X": 0.7795210480690002,
                      "Y": 0.28257042169570923
                  },
                  {
                      "X": 0.4459124803543091,
                      "Y": 0.4225352108478546
                  }
              ]
          }
      },
      {
          "DetectedText": "NO",
          "Type": "WORD",
          "Id": 11,
          "ParentId": 2,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.0674247071146965,
                  "Height": 0.04396221041679382,
                  "Left": 0.3707679510116577,
                  "Top": 0.40140846371650696
              },
              "Polygon": [
                  {
                      "X": 0.3707679510116577,
                      "Y": 0.40140846371650696
                  },
                  {
                      "X": 0.43352600932121277,
                      "Y": 0.3767605721950531
                  },
                  {
                      "X": 0.44838976860046387,
                      "Y": 0.41813379526138306
                  },
                  {
                      "X": 0.38480594754219055,
                      "Y": 0.44366195797920227
                  }
              ]
          }
      },
      {
          "DetectedText": "Area",
          "Type": "WORD",
          "Id": 13,
          "ParentId": 3,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.13689030706882477,
                  "Height": 0.052951060235500336,
                  "Left": 0.6168456077575684,
                  "Top": 0.40669015049934387
              },
              "Polygon": [
                  {
                      "X": 0.6168456077575684,
                      "Y": 0.40669015049934387
                  },
                  {
                      "X": 0.7481420040130615,
                      "Y": 0.36795774102211
                  },
                  {
                      "X": 0.7621800303459167,
                      "Y": 0.4190140962600708
                  },
                  {
                      "X": 0.6300578117370605,
                      "Y": 0.45862674713134766
                  }
              ]
          }
      },
      {
          "DetectedText": "Restricted",
          "Type": "WORD",
          "Id": 12,
          "ParentId": 3,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.23846501111984253,
                  "Height": 0.05617954954504967,
                  "Left": 0.3864574730396271,
                  "Top": 0.48943662643432617
              },
              "Polygon": [
                  {
                      "X": 0.3864574730396271,
                      "Y": 0.48943662643432617
                  },
                  {
                      "X": 0.6110652089118958,
                      "Y": 0.40933099389076233
                  },
                  {
                      "X": 0.6275805234909058,
                      "Y": 0.4630281627178192
                  },
                  {
                      "X": 0.4029727578163147,
                      "Y": 0.5431337952613831
                  }
              ]
          }
      },
      {
          "DetectedText": "Out",
          "Type": "WORD",
          "Id": 15,
          "ParentId": 4,
          "Confidence": 99.2820053100586,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.09642744064331055,
                  "Height": 0.0582658089697361,
                  "Left": 0.5763831734657288,
                  "Top": 0.48591548204421997
              },
              "Polygon": [
                  {
                      "X": 0.5763831734657288,
                      "Y": 0.48591548204421997
                  },
                  {
                      "X": 0.6688687205314636,
                      "Y": 0.45862674713134766
                  },
                  {
                      "X": 0.6837324500083923,
                      "Y": 0.514964759349823
                  },
                  {
                      "X": 0.5904211401939392,
                      "Y": 0.5413732528686523
                  }
              ]
          }
      },
      {
          "DetectedText": "Keep",
          "Type": "WORD",
          "Id": 14,
          "ParentId": 4,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.11941835284233093,
                  "Height": 0.06319853663444519,
                  "Left": 0.45086705684661865,
                  "Top": 0.5272887349128723
              },
              "Polygon": [
                  {
                      "X": 0.45086705684661865,
                      "Y": 0.5272887349128723
                  },
                  {
                      "X": 0.5664739608764648,
                      "Y": 0.49735915660858154
                  },
                  {
                      "X": 0.5805119872093201,
                      "Y": 0.5589788556098938
                  },
                  {
                      "X": 0.46407926082611084,
                      "Y": 0.5889084339141846
                  }
              ]
          }
      },
      {
          "DetectedText": "Authorized",
          "Type": "WORD",
          "Id": 16,
          "ParentId": 5,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.27435359358787537,
                  "Height": 0.06491629034280777,
                  "Left": 0.42774567008018494,
                  "Top": 0.6461267471313477
              },
              "Polygon": [
                  {
                      "X": 0.42774567008018494,
                      "Y": 0.6461267471313477
                  },
                  {
                      "X": 0.6944673657417297,
                      "Y": 0.5818662047386169
                  },
                  {
                      "X": 0.708505392074585,
                      "Y": 0.6452465057373047
                  },
                  {
                      "X": 0.4409578740596771,
                      "Y": 0.7095070481300354
                  }
              ]
          }
      },
      {
          "DetectedText": "Only",
          "Type": "WORD",
          "Id": 18,
          "ParentId": 6,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.1274307668209076,
                  "Height": 0.08105761557817459,
                  "Left": 0.6391412019729614,
                  "Top": 0.6698943376541138
              },
              "Polygon": [
                  {
                      "X": 0.6391412019729614,
                      "Y": 0.6698943376541138
                  },
                  {
                      "X": 0.7646573185920715,
                      "Y": 0.6478873491287231
                  },
                  {
                      "X": 0.7770437598228455,
                      "Y": 0.7279929518699646
                  },
                  {
                      "X": 0.6498761177062988,
                      "Y": 0.75
                  }
              ]
          }
      },
      {
          "DetectedText": "Personnel",
          "Type": "WORD",
          "Id": 17,
          "ParentId": 6,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.2358739823102951,
                  "Height": 0.06168077513575554,
                  "Left": 0.39388933777809143,
                  "Top": 0.7262324094772339
              },
              "Polygon": [
                  {
                      "X": 0.39388933777809143,
                      "Y": 0.7262324094772339
                  },
                  {
                      "X": 0.625103235244751,
                      "Y": 0.6795774698257446
                  },
                  {
                      "X": 0.6358381509780884,
                      "Y": 0.7403169274330139
                  },
                  {
                      "X": 0.40379852056503296,
                      "Y": 0.7869718074798584
                  }
              ]
          }
      },
      {
          "DetectedText": "and",
          "Type": "WORD",
          "Id": 21,
          "ParentId": 7,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.048409271985292435,
                  "Height": 0.03108527883887291,
                  "Left": 0.5879438519477844,
                  "Top": 0.8406690359115601
              },
              "Polygon": [
                  {
                      "X": 0.5879438519477844,
                      "Y": 0.8406690359115601
                  },
                  {
                      "X": 0.6358381509780884,
                      "Y": 0.8336267471313477
                  },
                  {
                      "X": 0.6399669647216797,
                      "Y": 0.8644366264343262
                  },
                  {
                      "X": 0.5912469029426575,
                      "Y": 0.8714788556098938
                  }
              ]
          }
      },
      {
          "DetectedText": "U.S.",
          "Type": "WORD",
          "Id": 19,
          "ParentId": 7,
          "Confidence": 99.74008178710938,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.040500711649656296,
                  "Height": 0.028217386454343796,
                  "Left": 0.4541701078414917,
                  "Top": 0.8573943376541138
              },
              "Polygon": [
                  {
                      "X": 0.4541701078414917,
                      "Y": 0.8573943376541138
                  },
                  {
                      "X": 0.4946325421333313,
                      "Y": 0.8556337952613831
                  },
                  {
                      "X": 0.4962840676307678,
                      "Y": 0.8838028311729431
                  },
                  {
                      "X": 0.45499587059020996,
                      "Y": 0.8855633735656738
                  }
              ]
          }
      },
      {
          "DetectedText": "Customs",
          "Type": "WORD",
          "Id": 20,
          "ParentId": 7,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.09381238371133804,
                  "Height": 0.02827773615717888,
                  "Left": 0.4946325421333313,
                  "Top": 0.8538732528686523
              },
              "Polygon": [
                  {
                      "X": 0.4946325421333313,
                      "Y": 0.8538732528686523
                  },
                  {
                      "X": 0.5879438519477844,
                      "Y": 0.8441901206970215
                  },
                  {
                      "X": 0.5904211401939392,
                      "Y": 0.8723591566085815
                  },
                  {
                      "X": 0.4971098303794861,
                      "Y": 0.8820422291755676
                  }
              ]
          }
      },
      {
          "DetectedText": "Border",
          "Type": "WORD",
          "Id": 22,
          "ParentId": 8,
          "Confidence": 100,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.07218578457832336,
                  "Height": 0.027400946244597435,
                  "Left": 0.4541701078414917,
                  "Top": 0.8917253613471985
              },
              "Polygon": [
                  {
                      "X": 0.4541701078414917,
                      "Y": 0.8917253613471985
                  },
                  {
                      "X": 0.52601158618927,
                      "Y": 0.8846830725669861
                  },
                  {
                      "X": 0.5284888744354248,
                      "Y": 0.9119718074798584
                  },
                  {
                      "X": 0.4558216333389282,
                      "Y": 0.9190140962600708
                  }
              ]
          }
      },
      {
          "DetectedText": "Protection",
          "Type": "WORD",
          "Id": 23,
          "ParentId": 8,
          "Confidence": 98.97674560546875,
          "Geometry": {
              "BoundingBox": {
                  "Width": 0.11790431290864944,
                  "Height": 0.029236480593681335,
                  "Left": 0.5293146371841431,
                  "Top": 0.8838028311729431
              },
              "Polygon": [
                  {
                      "X": 0.5293146371841431,
                      "Y": 0.8838028311729431
                  },
                  {
                      "X": 0.6465730667114258,
                      "Y": 0.8714788556098938
                  },
                  {
                      "X": 0.6498761177062988,
                      "Y": 0.9005281925201416
                  },
                  {
                      "X": 0.5317919254302979,
                      "Y": 0.9128521084785461
                  }
              ]
          }
      }
  ],
  "TextModelVersion": "3.0"
};

export const face_compare_dummy = {
  "SourceImageFace": {
      "BoundingBox": {
          "Width": 0.18168188631534576,
          "Height": 0.3084155321121216,
          "Left": 0.4508496820926666,
          "Top": 0.15961454808712006
      },
      "Confidence": 99.99756622314453
  },
  "FaceMatches": [
      {
          "Similarity": 99.4889144897461,
          "Face": {
              "BoundingBox": {
                  "Width": 0.24631543457508087,
                  "Height": 0.32071226835250854,
                  "Left": 0.48466944694519043,
                  "Top": 0.13275718688964844
              },
              "Confidence": 99.99641418457031,
              "Landmarks": [
                  {
                      "Type": "eyeLeft",
                      "X": 0.6241505742073059,
                      "Y": 0.27038484811782837
                  },
                  {
                      "Type": "eyeRight",
                      "X": 0.7023299932479858,
                      "Y": 0.2795058786869049
                  },
                  {
                      "Type": "mouthLeft",
                      "X": 0.6018602848052979,
                      "Y": 0.3687259554862976
                  },
                  {
                      "Type": "mouthRight",
                      "X": 0.6672302484512329,
                      "Y": 0.37552082538604736
                  },
                  {
                      "Type": "nose",
                      "X": 0.690095841884613,
                      "Y": 0.34309038519859314
                  }
              ],
              "Pose": {
                  "Roll": 10.90487289428711,
                  "Yaw": 42.37111282348633,
                  "Pitch": -6.283542156219482
              },
              "Quality": {
                  "Brightness": 70.08644104003906,
                  "Sharpness": 96.61495208740234
              }
          }
      }
  ],
  "UnmatchedFaces": []
};

export const ppe_detect_dummy = {
  "ProtectiveEquipmentModelVersion": "1.0",
  "Persons": [
      {
          "BodyParts": [
              {
                  "Name": "FACE",
                  "Confidence": 99.0025634765625,
                  "EquipmentDetections": [
                      {
                          "BoundingBox": {
                              "Width": 0.050268739461898804,
                              "Height": 0.06187485530972481,
                              "Left": 0.6522685289382935,
                              "Top": 0.31160593032836914
                          },
                          "Confidence": 99.64935302734375,
                          "Type": "FACE_COVER",
                          "CoversBodyPart": {
                              "Confidence": 76.12340545654297,
                              "Value": true
                          }
                      }
                  ]
              },
              {
                  "Name": "LEFT_HAND",
                  "Confidence": 99.34854888916016,
                  "EquipmentDetections": [
                      {
                          "BoundingBox": {
                              "Width": 0.04602836072444916,
                              "Height": 0.07013439387083054,
                              "Left": 0.744240403175354,
                              "Top": 0.5928517580032349
                          },
                          "Confidence": 96.98529052734375,
                          "Type": "HAND_COVER",
                          "CoversBodyPart": {
                              "Confidence": 99.3873062133789,
                              "Value": true
                          }
                      }
                  ]
              },
              {
                  "Name": "RIGHT_HAND",
                  "Confidence": 73.8131103515625,
                  "EquipmentDetections": []
              },
              {
                  "Name": "HEAD",
                  "Confidence": 99.9252700805664,
                  "EquipmentDetections": []
              }
          ],
          "BoundingBox": {
              "Width": 0.2089971899986267,
              "Height": 0.7524999976158142,
              "Left": 0.6279287934303284,
              "Top": 0.23624999821186066
          },
          "Confidence": 99.84943389892578,
          "Id": 1
      },{
        "BodyParts": [
            {
                "Name": "FACE",
                "Confidence": 99.05427551269531,
                "EquipmentDetections": [
                    {
                        "BoundingBox": {
                            "Width": 0.06334548443555832,
                            "Height": 0.09218402951955795,
                            "Left": 0.22919389605522156,
                            "Top": 0.16889554262161255
                        },
                        "Confidence": 99.98274993896484,
                        "Type": "FACE_COVER",
                        "CoversBodyPart": {
                            "Confidence": 95.81717681884766,
                            "Value": true
                        }
                    }
                ]
            },
            {
                "Name": "LEFT_HAND",
                "Confidence": 98.62016296386719,
                "EquipmentDetections": []
            },
            {
                "Name": "RIGHT_HAND",
                "Confidence": 99.57721710205078,
                "EquipmentDetections": []
            },
            {
                "Name": "HEAD",
                "Confidence": 99.99833679199219,
                "EquipmentDetections": []
            }
        ],
        "BoundingBox": {
            "Width": 0.2914714217185974,
            "Height": 0.9287499785423279,
            "Left": 0.10496719926595688,
            "Top": 0.0637499988079071
        },
        "Confidence": 99.99386596679688,
        "Id": 0
    },
      {
          "BodyParts": [
              {
                  "Name": "FACE",
                  "Confidence": 98.5594253540039,
                  "EquipmentDetections": [
                      {
                          "BoundingBox": {
                              "Width": 0.04975133761763573,
                              "Height": 0.06329503655433655,
                              "Left": 0.4379417896270752,
                              "Top": 0.19658075273036957
                          },
                          "Confidence": 99.97799682617188,
                          "Type": "FACE_COVER",
                          "CoversBodyPart": {
                              "Confidence": 98.07199096679688,
                              "Value": true
                          }
                      }
                  ]
              },
              {
                  "Name": "LEFT_HAND",
                  "Confidence": 97.53223419189453,
                  "EquipmentDetections": []
              },
              {
                  "Name": "RIGHT_HAND",
                  "Confidence": 97.10836029052734,
                  "EquipmentDetections": []
              },
              {
                  "Name": "HEAD",
                  "Confidence": 99.94258880615234,
                  "EquipmentDetections": []
              }
          ],
          "BoundingBox": {
              "Width": 0.21555763483047485,
              "Height": 0.5049999952316284,
              "Left": 0.36457356810569763,
              "Top": 0.11124999821186066
          },
          "Confidence": 99.95829010009766,
          "Id": 2
      }
  ],
  "Summary": {
      "PersonsWithRequiredEquipment": [],
      "PersonsWithoutRequiredEquipment": [
          0,
          1,
          2
      ],
      "PersonsIndeterminate": [
          3,
          4,
          5,
          6,
          7
      ]
  }
};

export const label_detect_dummy = [
  {
      name: "Pedestrian",
      value: "99.96%"
  },
  {
      name: "Person",
      value: "99.96%"
  },
  {
      name: "Human",
      value: "99.96%"
  },
  {
    name: "Downtown",
      value: "98.44%"
  },
  {
    name: "Urban",
      value: "98.44%"
  },
  {
    name: "City",
      value: "98.44%"
  },
  {
    name: "Building",
      value: "98.44%"
  },
  {
    name: "Metropolis",
      value: "96.79%"
  },
  {
    name: "Intersection",
      value: "95.37%"
  },
  {
      name: "Road",
      value: "95.37%"
  },
  {
      name: "Tarmac",
      value: "95.30%"
  },
  {
      name: "Car",
      value: "92.51%"
  },
  {
      name: "Transportation",
      value: "92.51%"
  },
  {
      name: "Vehicle",
      value: "92.51%"
  },
  {
      name: "Architecture",
      value: "89.45%"
  }
];

export const face_analysis_dummy = [
  {
      "BoundingBox": {
          "Width": 0.06144896522164345,
          "Height": 0.10027243942022324,
          "Left": 0.8045965433120728,
          "Top": 0.32553502917289734
      },
      "AgeRange": {
          "Low": 22,
          "High": 34
      },
      "Smile": {
          "Value": false,
          "Confidence": 77.59378051757812
      },
      "Eyeglasses": {
          "Value": false,
          "Confidence": 99.61045837402344
      },
      "Sunglasses": {
          "Value": false,
          "Confidence": 99.84982299804688
      },
      "Gender": {
          "Value": "Female",
          "Confidence": 99.41694641113281
      },
      "Beard": {
          "Value": false,
          "Confidence": 99.28433227539062
      },
      "Mustache": {
          "Value": false,
          "Confidence": 99.8052749633789
      },
      "EyesOpen": {
          "Value": true,
          "Confidence": 78.94155883789062
      },
      "MouthOpen": {
          "Value": false,
          "Confidence": 96.67028045654297
      },
      "Emotions": [
          {
              "Type": "CALM",
              "Confidence": 65.95339965820312
          },
          {
              "Type": "HAPPY",
              "Confidence": 31.546228408813477
          },
          {
              "Type": "SAD",
              "Confidence": 0.9735211730003357
          },
          {
              "Type": "ANGRY",
              "Confidence": 0.5321785807609558
          },
          {
              "Type": "DISGUSTED",
              "Confidence": 0.35970064997673035
          },
          {
              "Type": "FEAR",
              "Confidence": 0.2303580790758133
          },
          {
              "Type": "CONFUSED",
              "Confidence": 0.21557384729385376
          },
          {
              "Type": "SURPRISED",
              "Confidence": 0.18903912603855133
          }
      ],
      "Landmarks": [
          {
              "Type": "eyeLeft",
              "X": 0.8261720538139343,
              "Y": 0.36847954988479614
          },
          {
              "Type": "eyeRight",
              "X": 0.8521066308021545,
              "Y": 0.37165677547454834
          },
          {
              "Type": "mouthLeft",
              "X": 0.826246976852417,
              "Y": 0.40381288528442383
          },
          {
              "Type": "mouthRight",
              "X": 0.8477789759635925,
              "Y": 0.40636345744132996
          },
          {
              "Type": "nose",
              "X": 0.8403279781341553,
              "Y": 0.3859199583530426
          },
          {
              "Type": "leftEyeBrowLeft",
              "X": 0.8161919116973877,
              "Y": 0.3598702847957611
          },
          {
              "Type": "leftEyeBrowRight",
              "X": 0.8251923322677612,
              "Y": 0.3552877902984619
          },
          {
              "Type": "leftEyeBrowUp",
              "X": 0.8330290913581848,
              "Y": 0.35756978392601013
          },
          {
              "Type": "rightEyeBrowLeft",
              "X": 0.847893238067627,
              "Y": 0.3593938648700714
          },
          {
              "Type": "rightEyeBrowRight",
              "X": 0.8551337718963623,
              "Y": 0.35895684361457825
          },
          {
              "Type": "rightEyeBrowUp",
              "X": 0.8613187670707703,
              "Y": 0.36536359786987305
          },
          {
              "Type": "leftEyeLeft",
              "X": 0.8212507963180542,
              "Y": 0.36806565523147583
          },
          {
              "Type": "leftEyeRight",
              "X": 0.8312535285949707,
              "Y": 0.36934909224510193
          },
          {
              "Type": "leftEyeUp",
              "X": 0.8263100385665894,
              "Y": 0.36652401089668274
          },
          {
              "Type": "leftEyeDown",
              "X": 0.8261944651603699,
              "Y": 0.36997130513191223
          },
          {
              "Type": "rightEyeLeft",
              "X": 0.8469229936599731,
              "Y": 0.37127619981765747
          },
          {
              "Type": "rightEyeRight",
              "X": 0.8563801646232605,
              "Y": 0.372331827878952
          },
          {
              "Type": "rightEyeUp",
              "X": 0.8523482084274292,
              "Y": 0.3697033226490021
          },
          {
              "Type": "rightEyeDown",
              "X": 0.8518631458282471,
              "Y": 0.373085618019104
          },
          {
              "Type": "noseLeft",
              "X": 0.8337103128433228,
              "Y": 0.3907964527606964
          },
          {
              "Type": "noseRight",
              "X": 0.8432915806770325,
              "Y": 0.3919329345226288
          },
          {
              "Type": "mouthUp",
              "X": 0.8381955027580261,
              "Y": 0.3994560241699219
          },
          {
              "Type": "mouthDown",
              "X": 0.8372165560722351,
              "Y": 0.41034361720085144
          },
          {
              "Type": "leftPupil",
              "X": 0.8261720538139343,
              "Y": 0.36847954988479614
          },
          {
              "Type": "rightPupil",
              "X": 0.8521066308021545,
              "Y": 0.37165677547454834
          },
          {
              "Type": "upperJawlineLeft",
              "X": 0.8071469068527222,
              "Y": 0.3709443211555481
          },
          {
              "Type": "midJawlineLeft",
              "X": 0.8105180859565735,
              "Y": 0.40914955735206604
          },
          {
              "Type": "chinBottom",
              "X": 0.8350357413291931,
              "Y": 0.4297143220901489
          },
          {
              "Type": "midJawlineRight",
              "X": 0.8564690947532654,
              "Y": 0.4146699905395508
          },
          {
              "Type": "upperJawlineRight",
              "X": 0.8638128042221069,
              "Y": 0.3778466284275055
          }
      ],
      "Pose": {
          "Roll": 5.294699192047119,
          "Yaw": 4.165914058685303,
          "Pitch": 12.412052154541016
      },
      "Quality": {
          "Brightness": 88.33502960205078,
          "Sharpness": 32.20803451538086
      },
      "Confidence": 99.99635314941406
  },
  {
      "BoundingBox": {
          "Width": 0.05997568368911743,
          "Height": 0.0974825918674469,
          "Left": 0.4937332272529602,
          "Top": 0.239732563495636
      },
      "AgeRange": {
          "Low": 17,
          "High": 29
      },
      "Smile": {
          "Value": true,
          "Confidence": 96.44116973876953
      },
      "Eyeglasses": {
          "Value": false,
          "Confidence": 99.80907440185547
      },
      "Sunglasses": {
          "Value": false,
          "Confidence": 99.92632293701172
      },
      "Gender": {
          "Value": "Female",
          "Confidence": 99.8832015991211
      },
      "Beard": {
          "Value": false,
          "Confidence": 99.63355255126953
      },
      "Mustache": {
          "Value": false,
          "Confidence": 99.90776062011719
      },
      "EyesOpen": {
          "Value": true,
          "Confidence": 82.10333251953125
      },
      "MouthOpen": {
          "Value": true,
          "Confidence": 98.53301239013672
      },
      "Emotions": [
          {
              "Type": "HAPPY",
              "Confidence": 99.7445068359375
          },
          {
              "Type": "CALM",
              "Confidence": 0.09995527565479279
          },
          {
              "Type": "ANGRY",
              "Confidence": 0.04132108762860298
          },
          {
              "Type": "FEAR",
              "Confidence": 0.035776637494564056
          },
          {
              "Type": "SAD",
              "Confidence": 0.03039018251001835
          },
          {
              "Type": "SURPRISED",
              "Confidence": 0.024895692244172096
          },
          {
              "Type": "DISGUSTED",
              "Confidence": 0.012017618864774704
          },
          {
              "Type": "CONFUSED",
              "Confidence": 0.01113133504986763
          }
      ],
      "Landmarks": [
          {
              "Type": "eyeLeft",
              "X": 0.5072985887527466,
              "Y": 0.28477123379707336
          },
          {
              "Type": "eyeRight",
              "X": 0.532062292098999,
              "Y": 0.27441897988319397
          },
          {
              "Type": "mouthLeft",
              "X": 0.5178456902503967,
              "Y": 0.3175339698791504
          },
          {
              "Type": "mouthRight",
              "X": 0.5383774638175964,
              "Y": 0.30889761447906494
          },
          {
              "Type": "nose",
              "X": 0.5213651061058044,
              "Y": 0.2913552522659302
          },
          {
              "Type": "leftEyeBrowLeft",
              "X": 0.49719879031181335,
              "Y": 0.28268757462501526
          },
          {
              "Type": "leftEyeBrowRight",
              "X": 0.5018741488456726,
              "Y": 0.2726163864135742
          },
          {
              "Type": "leftEyeBrowUp",
              "X": 0.5089867115020752,
              "Y": 0.27028071880340576
          },
          {
              "Type": "rightEyeBrowLeft",
              "X": 0.5232080817222595,
              "Y": 0.264302134513855
          },
          {
              "Type": "rightEyeBrowRight",
              "X": 0.530491292476654,
              "Y": 0.2606337368488312
          },
          {
              "Type": "rightEyeBrowUp",
              "X": 0.5402430891990662,
              "Y": 0.26472368836402893
          },
          {
              "Type": "leftEyeLeft",
              "X": 0.5032406449317932,
              "Y": 0.2871815264225006
          },
          {
              "Type": "leftEyeRight",
              "X": 0.5123010277748108,
              "Y": 0.2829945385456085
          },
          {
              "Type": "leftEyeUp",
              "X": 0.5066538453102112,
              "Y": 0.2827431261539459
          },
          {
              "Type": "leftEyeDown",
              "X": 0.5078062415122986,
              "Y": 0.2861185073852539
          },
          {
              "Type": "rightEyeLeft",
              "X": 0.5272650718688965,
              "Y": 0.27674171328544617
          },
          {
              "Type": "rightEyeRight",
              "X": 0.5367569923400879,
              "Y": 0.2731800377368927
          },
          {
              "Type": "rightEyeUp",
              "X": 0.5315136313438416,
              "Y": 0.2723430395126343
          },
          {
              "Type": "rightEyeDown",
              "X": 0.5322948694229126,
              "Y": 0.27588218450546265
          },
          {
              "Type": "noseLeft",
              "X": 0.5194491744041443,
              "Y": 0.30063459277153015
          },
          {
              "Type": "noseRight",
              "X": 0.5285807847976685,
              "Y": 0.29682353138923645
          },
          {
              "Type": "mouthUp",
              "X": 0.5257770419120789,
              "Y": 0.3061797618865967
          },
          {
              "Type": "mouthDown",
              "X": 0.5287663340568542,
              "Y": 0.31715279817581177
          },
          {
              "Type": "leftPupil",
              "X": 0.5072985887527466,
              "Y": 0.28477123379707336
          },
          {
              "Type": "rightPupil",
              "X": 0.532062292098999,
              "Y": 0.27441897988319397
          },
          {
              "Type": "upperJawlineLeft",
              "X": 0.4972286820411682,
              "Y": 0.30044791102409363
          },
          {
              "Type": "midJawlineLeft",
              "X": 0.5110085010528564,
              "Y": 0.3336007595062256
          },
          {
              "Type": "chinBottom",
              "X": 0.53443843126297,
              "Y": 0.3372798264026642
          },
          {
              "Type": "midJawlineRight",
              "X": 0.5547987818717957,
              "Y": 0.3154437243938446
          },
          {
              "Type": "upperJawlineRight",
              "X": 0.5512936115264893,
              "Y": 0.27793338894844055
          }
      ],
      "Pose": {
          "Roll": -17.804908752441406,
          "Yaw": -1.5652601718902588,
          "Pitch": 20.661731719970703
      },
      "Quality": {
          "Brightness": 92.21146392822266,
          "Sharpness": 26.1773681640625
      },
      "Confidence": 99.99783325195312
  },
  {
      "BoundingBox": {
          "Width": 0.05980965867638588,
          "Height": 0.09172292053699493,
          "Left": 0.2559358775615692,
          "Top": 0.21995535492897034
      },
      "AgeRange": {
          "Low": 20,
          "High": 32
      },
      "Smile": {
          "Value": false,
          "Confidence": 76.45128631591797
      },
      "Eyeglasses": {
          "Value": false,
          "Confidence": 99.80896759033203
      },
      "Sunglasses": {
          "Value": false,
          "Confidence": 99.8979263305664
      },
      "Gender": {
          "Value": "Female",
          "Confidence": 99.84292602539062
      },
      "Beard": {
          "Value": false,
          "Confidence": 99.54712677001953
      },
      "Mustache": {
          "Value": false,
          "Confidence": 99.8509750366211
      },
      "EyesOpen": {
          "Value": true,
          "Confidence": 86.97029113769531
      },
      "MouthOpen": {
          "Value": false,
          "Confidence": 82.72183990478516
      },
      "Emotions": [
          {
              "Type": "CALM",
              "Confidence": 58.93027877807617
          },
          {
              "Type": "HAPPY",
              "Confidence": 38.94536209106445
          },
          {
              "Type": "SAD",
              "Confidence": 1.005434513092041
          },
          {
              "Type": "FEAR",
              "Confidence": 0.4259025752544403
          },
          {
              "Type": "ANGRY",
              "Confidence": 0.19742237031459808
          },
          {
              "Type": "CONFUSED",
              "Confidence": 0.17868678271770477
          },
          {
              "Type": "DISGUSTED",
              "Confidence": 0.1726359874010086
          },
          {
              "Type": "SURPRISED",
              "Confidence": 0.1442810297012329
          }
      ],
      "Landmarks": [
          {
              "Type": "eyeLeft",
              "X": 0.27512362599372864,
              "Y": 0.2576887905597687
          },
          {
              "Type": "eyeRight",
              "X": 0.3007497489452362,
              "Y": 0.2580898106098175
          },
          {
              "Type": "mouthLeft",
              "X": 0.27742671966552734,
              "Y": 0.28946825861930847
          },
          {
              "Type": "mouthRight",
              "X": 0.2986123561859131,
              "Y": 0.28975075483322144
          },
          {
              "Type": "nose",
              "X": 0.28847238421440125,
              "Y": 0.26431405544281006
          },
          {
              "Type": "leftEyeBrowLeft",
              "X": 0.26552242040634155,
              "Y": 0.25333258509635925
          },
          {
              "Type": "leftEyeBrowRight",
              "X": 0.27313104271888733,
              "Y": 0.2443820834159851
          },
          {
              "Type": "leftEyeBrowUp",
              "X": 0.2806265652179718,
              "Y": 0.24431489408016205
          },
          {
              "Type": "rightEyeBrowLeft",
              "X": 0.2953028678894043,
              "Y": 0.24451203644275665
          },
          {
              "Type": "rightEyeBrowRight",
              "X": 0.30265703797340393,
              "Y": 0.24480323493480682
          },
          {
              "Type": "rightEyeBrowUp",
              "X": 0.30995985865592957,
              "Y": 0.25398367643356323
          },
          {
              "Type": "leftEyeLeft",
              "X": 0.2705507278442383,
              "Y": 0.25873926281929016
          },
          {
              "Type": "leftEyeRight",
              "X": 0.28019410371780396,
              "Y": 0.25798317790031433
          },
          {
              "Type": "leftEyeUp",
              "X": 0.27507394552230835,
              "Y": 0.2554856836795807
          },
          {
              "Type": "leftEyeDown",
              "X": 0.27529019117355347,
              "Y": 0.25898197293281555
          },
          {
              "Type": "rightEyeLeft",
              "X": 0.2956758141517639,
              "Y": 0.25822946429252625
          },
          {
              "Type": "rightEyeRight",
              "X": 0.3051585257053375,
              "Y": 0.25925153493881226
          },
          {
              "Type": "rightEyeUp",
              "X": 0.30076754093170166,
              "Y": 0.25586897134780884
          },
          {
              "Type": "rightEyeDown",
              "X": 0.30057764053344727,
              "Y": 0.25935444235801697
          },
          {
              "Type": "noseLeft",
              "X": 0.28337907791137695,
              "Y": 0.27423906326293945
          },
          {
              "Type": "noseRight",
              "X": 0.2927452623844147,
              "Y": 0.27435991168022156
          },
          {
              "Type": "mouthUp",
              "X": 0.28812018036842346,
              "Y": 0.28100621700286865
          },
          {
              "Type": "mouthDown",
              "X": 0.2880869507789612,
              "Y": 0.29211241006851196
          },
          {
              "Type": "leftPupil",
              "X": 0.27512362599372864,
              "Y": 0.2576887905597687
          },
          {
              "Type": "rightPupil",
              "X": 0.3007497489452362,
              "Y": 0.2580898106098175
          },
          {
              "Type": "upperJawlineLeft",
              "X": 0.25942134857177734,
              "Y": 0.2732880711555481
          },
          {
              "Type": "midJawlineLeft",
              "X": 0.26488983631134033,
              "Y": 0.30659645795822144
          },
          {
              "Type": "chinBottom",
              "X": 0.2879057228565216,
              "Y": 0.31352293491363525
          },
          {
              "Type": "midJawlineRight",
              "X": 0.30985668301582336,
              "Y": 0.30726802349090576
          },
          {
              "Type": "upperJawlineRight",
              "X": 0.31514713168144226,
              "Y": 0.274117112159729
          }
      ],
      "Pose": {
          "Roll": 0.8963131308555603,
          "Yaw": 0.9672368168830872,
          "Pitch": 30.129531860351562
      },
      "Quality": {
          "Brightness": 93.99349975585938,
          "Sharpness": 16.49492073059082
      },
      "Confidence": 99.99526977539062
  }
];

